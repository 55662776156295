import React, { useState } from 'react';
import { Typography, Grid, Box, CardContent, IconButton, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'components';
import { WHITE } from 'constants/colors';
import { Timestamp } from 'firebase/firestore';

const useStyles = makeStyles((theme) => ({
  Card: {
    display: 'flex',
    flexDirection: 'column',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',
    width: '343px',
    height: '100%',
    maxWidth: '374px',
    transition: 'height 0.3s ease',
    paddingBottom: 0,
  },
  customBackground: {
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#F4F6FA',
    padding: '16px 24px',
    [theme.breakpoints.down(991)]: {
      padding: '12px 24px',
      height: 'auto',
    },
  },
  contentContainer: {
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    height: '100%',
    minHeight: '16px'
  },
  Box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 1,
    gap: '4px',
  },
  BoldTextTitle: {
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: "Noto Sans TC",
    textAlign: 'left',
    fontStyle: 'normal',
    lineHeight: '130%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  BoldTextContent: {
    fontWeight: 500,
    fontSize: '22px',
    fontFamily: "Noto Sans TC",
    textAlign: 'left',
    fontStyle: 'normal',
    lineHeight: '130%',
  },
  SecondText: {
    fontWeight: 400,
    color: '#4D5461',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '140%',
    height: 'fit-content',
    textAlign: 'left',
  },
  NoteText: {
    fontFamily: 'Noto Sans TC',
    fontSize: "16px",
    color: '#141921',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    textAlign: 'left',
    transition: 'max-height 0.3s ease',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#C6CBD3',
    margin: '8px 0',
  },
  button: {
    padding: '10px 24px',
    width: '312px',// fix width
    maxWidth: '312px',
    height: '48px',
    maxheight: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '100px',
    background: 'linear-gradient(97deg, #9FE4FF 0%, #9EE7B2 100%)',
    color: '#141921',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      background: 'linear-gradient(97deg, #9FE4FF 0%, #9EE7B2 100%)',
      opacity: 0.8,
    },
  },
  showMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#4D5461',
    fontFamily: 'Noto Sans TC',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    cursor: 'pointer',
  },
  iconButton: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '7.5px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: '#4D5461',
    borderRadius: '32px',
    background: 'var(--Interface-Greys-Grey7, #E5E8EE)',
  },
  icon: {
    width: '10px',
    height: '6px',
    flexShrink: 0,
  },
  expandableContent: {
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
}));

interface Referrer {
  preferredName?: string;
  name: string;
  department?: string;
  jobTitle: string;
  location?: string;
  applicantNote?: string;
  referredCount?: number;
  applicationCount?: number;
  lastReferredDate?: Timestamp;
  role?: string;
  internship?: string;
  careerPage?: string;
}

interface ReferrerDetailsProps {
  referrer: Referrer;
  onButtonClick: () => void;
}

const Divider = () => {
  const classes = useStyles();
  return <div className={classes.divider} />;
};

export const ReferrerDetails: React.FC<ReferrerDetailsProps> = ({
  referrer,
  onButtonClick,
}) => {
  const classes = useStyles();
  const maxLength = 60;

  const [showFullContent, setShowFullContent] = useState(false);

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const {
    department = "NA",
    location = "不限地點",
    role = "不限部門職位",
    careerPage,
    applicantNote = ''
  } = referrer;

  const openExternalUrl = () => {
    if (careerPage) {
      window.open(careerPage, '_blank');
    }
  };

  return (
    <CardContent className={classes.Card}>
      <Grid container alignItems="center" justifyContent="center" className={classes.customBackground}>
        <Grid container item xs={12} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText}>職稱</Typography>
              <Typography className={classes.BoldTextTitle} title={referrer.jobTitle}>
                {referrer.jobTitle}
              </Typography>
            </Box>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText}>所屬部門</Typography>
              <Typography className={classes.BoldTextTitle} title={department}>
                {department}
              </Typography>
            </Box>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText}>可內推地點</Typography>
              <Typography className={classes.BoldTextTitle} title={location}>
                {location}
              </Typography>
            </Box>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Box className={classes.Box}>
              <Typography className={classes.SecondText}>可內推職位</Typography>
              <Typography className={classes.BoldTextTitle} title={role}>
                {role}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.contentContainer}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.BoldTextContent}>{referrer.preferredName || referrer.name}</Typography>
          {careerPage &&
            <IconButton
              color="primary"
              sx={{ padding: 0, marginLeft: '4px' }}
              onClick={openExternalUrl}>
              <img
                src="/mingcute_external-link-fill.svg"
                width="24"
                height="24"
                alt="External Link"
              />
            </IconButton>}
        </Box>
        <Box 
          className={classes.expandableContent}
          sx={{
            maxHeight: showFullContent ? '1000px' : '60px',
          }}
        >
          <Typography className={classes.NoteText}>
            {applicantNote}
          </Typography>
        </Box>
        {applicantNote.length > maxLength &&
          <Box className={classes.showMoreContainer} onClick={handleToggleContent}>
            <IconButton className={classes.iconButton}>
            <img
              src={showFullContent ? '/HideMoreButtonNew.svg' : '/ExpandMoreButtonNew.svg'}
              alt="Toggle More"
              className={classes.icon}
            />
            </IconButton>
            <Typography>{showFullContent ? '顯示更少' : '顯示更多'}</Typography>
          </Box>}
      </Box>
      <CardActions sx={{ padding: '10px 24px 0px' }}> 
        <Button
          shape="round"
          onClick={onButtonClick}
          fullWidth
          className={classes.button}
        >
          幫我內推
        </Button>
      </CardActions>
    </CardContent>
  );
};